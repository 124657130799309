import styled from "styled-components"
import css from "@styled-system/css"
import logo from "../../../public/images/heartpayroll-logo.svg"
import { Stack } from "@ui/stack"
import Image from "next/image"
import Link from "next/link"
import { PageBlock } from "@ui/page-block"
import { Menu, MenuButton, MenuList, MenuLink, MenuItem } from "@reach/menu-button"
import "@reach/menu-button/styles.css"
import { logout } from "@app/logout"
import { Button } from "@ui/button"
import VisuallyHidden from "@reach/visually-hidden"
import { homepagePath, loginPath, signupPath, userEditPath } from "@app/routes"
import { useCurrentUser } from "react-current-user"
import { isClient } from "is-client"

export const Nav = styled((props) => {
  const [user] = useCurrentUser()

  return (
    <PageBlock as="nav" paddingY="3" borderBottom="1px solid" borderColor="gray.border" {...props}>
      <Stack direction="horizontal" justifyContent="space-between" alignItems="center">
        <Stack gap="3" direction="horizontal" alignItems="flex-end">
          <Link href={homepagePath} passHref>
            <a>
              <Image priority alt="" src={logo.src} height={logo.height / 2} width={logo.width / 2} layout="fixed" />
              <VisuallyHidden>HeartPayroll homepage</VisuallyHidden>
            </a>
          </Link>
          {isClient(user) && (
            <Link href={"/employees"} passHref>
              <Button variant="ghost" id="nav-login">
                Employees
              </Button>
            </Link>
          )}
          {user?.type === "employee" && (
            <Link href={"/employers"} passHref>
              <Button variant="ghost" id="nav-login">
                Employers
              </Button>
            </Link>
          )}
        </Stack>

        {!user && (
          <Stack gap="3" direction="horizontal">
            <Link href={loginPath} passHref>
              <Button variant="ghost" id="nav-login">
                Login
              </Button>
            </Link>
            <Link href={signupPath} passHref>
              <Button rel="noreferrer noopener" id="nav-sign-up">
                Sign up
              </Button>
            </Link>
          </Stack>
        )}
        {user && (
          <Menu>
            <MenuButton as="button" id="main-menu">
              {user.firstName} {user.lastName}
            </MenuButton>
            <MyMenuList>
              <Link href={userEditPath} passHref>
                <MenuLink as="a">Settings</MenuLink>
              </Link>
              <MenuItem onSelect={logout}>Logout</MenuItem>
            </MyMenuList>
          </Menu>
        )}
      </Stack>
    </PageBlock>
  )
})`
  [data-reach-menu-button] {
    background-color: transparent;
    border: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    ${css({
      color: "gray.heading",
      fontSize: "2",
    })}
  }
`

Nav.displayName = "Nav"

const MyMenuList = styled(MenuList)`
  ${css({
    top: "410px",
    padding: "0px",
    borderRadius: "small",
    "[data-reach-menu-item]": {
      padding: "0px",
      paddingX: 6,
      paddingY: 3,
    },
    "[data-reach-menu-item][data-selected]": {
      backgroundColor: "gray.background",
      color: "gray.text",
      textDecoration: "none",
    },
  })}
`
