import { payrollTeamEmail, phone } from "@app/contact-info"
import { Stack } from "@ui/stack"
import { Heading } from "@ui/heading"
import { useCurrentUser } from "react-current-user"
import { EarnRewards } from "@app/rewards-prompt"
import { MobileOnly } from "@ui/mobile-only"
import { isClient } from "is-client"

export const DefaultFooter = () => {
  const [user] = useCurrentUser()

  return (
    <Stack as="footer" gap="6" direction="vertical" pb="4">
      {isClient(user) && (
        <MobileOnly>
          <EarnRewards />
        </MobileOnly>
      )}
      <Stack gap="4" justifyContent="space-between" flexDirection={["column", "row"]}>
        <Stack gap="1">
          <Heading as="h2" level="4">
            Contact
          </Heading>
          <address>
            <a href={`mailto:${payrollTeamEmail}`}>{payrollTeamEmail}</a>
          </address>
          <address>
            <a href={`tel:${phone}`}>{phone}</a>
          </address>
        </Stack>

        <Stack gap="1">
          <Heading as="h2" level="4">
            Address
          </Heading>
          {/* eslint-disable-next-line sentence-case/sentence-case */}
          <address>
            20 Bay Street, 11th Floor
            <br />
            Toronto, ON
            <br />
            M5J 2N8
            <br />
          </address>
        </Stack>
      </Stack>
    </Stack>
  )
}

DefaultFooter.displayName = "SiteFooter"
