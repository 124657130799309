import { Nav } from "@layouts/topbar/topbar-nav"
import { DefaultFooter } from "@footers/default"
import Head from "next/head"
import { Stack, StackProps } from "@ui/stack"
import styled from "styled-components"
import css from "@styled-system/css"
import { useCurrentAdmin } from "react-current-admin"
import { CurrentAdminBanner } from "@banners/current-admin"
import { VerifyEmailBanner } from "@banners/verify-email"
import { useCurrentUser } from "react-current-user"
import { PageBlock } from "@ui/page-block"
import { ReactNode } from "react"

export const TOP_BAR_HEIGHT = "72px"

export type TopbarLayoutProps = {
  title: string
  description?: string
  disableVerifyAlert?: boolean
  children: ReactNode
} & StackProps

export const TopbarLayout: React.FC<TopbarLayoutProps> = ({
  title,
  description,
  disableVerifyAlert,
  children,
  ...props
}) => {
  const admin = useCurrentAdmin()
  const [user] = useCurrentUser()

  return (
    <>
      <Head>
        <title>
          {title}
          {title && " | "}HeartPayroll
        </title>
        <link rel="icon" href="/favicon.ico" />
        {description && <meta name="description" content={description} />}
      </Head>
      {admin && <CurrentAdminBanner {...admin} />}
      <Nav />
      <Body>
        <Stack as="main" {...(props as any)}>
          {!disableVerifyAlert && user && !user.isConfirmed && (
            <PageBlock>
              <VerifyEmailBanner />
            </PageBlock>
          )}
          {children}
        </Stack>
        <PageBlock>
          <DefaultFooter />
        </PageBlock>
      </Body>
    </>
  )
}

TopbarLayout.displayName = "Layout"
TopbarLayout.defaultProps = {
  pt: 6,
  gap: 3,
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - ${TOP_BAR_HEIGHT});
  ${css({
    gap: "7",
  })}
`
